import { useEffect } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/navigation';

import { css } from 'emotion';

import LoginBox from '../../components/organisms/login-box/index.tsx';
import AuthService from '../../services/auth';
import Settings from '../../services/settings';

const style = css`
  text-align: center;
  width: 100vw;
  height: 80vh;
  .input {
    margin-bottom: 20px;
  }
`;

function Login() {

  async function checkIsLogged() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('email') && urlParams.get('password')) {
      localStorage.clear();
      AuthService.logout();
      return;
    }

    try {
      if (AuthService.isLogged()) {
        window.location.href = '/details';
      }
    } catch (error) {
      console.error(error);
    }

  }


  useEffect(() => {
    checkIsLogged();
  }, []);

  return (
    <div style={{
      backgroundColor: '#F9F9F9',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Head>
        <title>Login | Gamefik Escolas no level máximo.</title>
      </Head>
      <img
        src="/assets/img/bg.png"
        alt=""
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          objectFit: 'cover',
          zIndex: 1,
        }}
      />

      <div style={{
        width: '100dvw',
        height: '100dvh',
        display: 'flex',
        justifyContent: 'flex-end',
        maxWidth: '1300px',
        zIndex: 2,
        alignItems: 'center',
      }}>
        <div
          style={{
            height: '80vh',
            width: '500px',
            maxWidth: '90vw',
            marginRight: '5vw',
            backgroundColor: 'white',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.25)',
            borderRadius: '20px',
            padding: '30px',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            src="/assets/img/logo2.png"
            style={{
              width: '100%',
            }}
          />
          <div style={{
            flex: 1
          }} />
          <LoginBox />
          <div style={{
            flex: 1
          }} />
        </div>
      </div>

    </div>
  );
}

export default Login;

import { useEffect, useState } from 'react';

import { useRouter } from 'next/navigation';

import Button from '@material-ui/core/Button';
import { css } from 'emotion';

import { useToast } from '@/context/useToast';

import AuthService from '../../../services/auth';
import Settings from '../../../services/settings';
import RocketLoading from '../../atoms/loading';
import SnackAlert from '../../atoms/snack-alert';
import ForgotPassword from '../forgot-password';

const style = css`
  .button_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

function LoginBox() {
  const [loading, setLoading] = useState('' as any);
  const [password, setPassword] = useState('' as any);
  const [username, setUsername] = useState('' as any);
  const [openEmail, setOpenEmail] = useState(false);

  const router = useRouter()
  const { showToast } = useToast()

  const changeUsernameHandler = ({ target: { value } }) => setUsername(value);
  const changePasswordHandler = ({ target: { value } }) => setPassword(value);


  useEffect(() => {
    setTimeout(() => {
      localStorage.clear();
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email');
      const password = urlParams.get('password');
      if (email && password) {
        setUsername(email);
        setPassword(password);
      }
    }, 1000);
  }, []);

  const onSubmitHandler = async () => {
    // clear local storage
    localStorage.clear();
    setLoading(true);
    try {
      const response = await AuthService.login({
        username,
        password,
        preferrAccount: '6516e5c39c42f10008f6a393',
      });
      if (!response.error && AuthService.isLogged()) {
        router.push('/details')
        return;
      }
      showToast("Usuário ou senha invalidos!");
    } catch (error) {
      console.error(error);
      showToast("Usuário ou senha invalidos!");
    }
    setLoading(false);
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <ForgotPassword open={openEmail} setOpen={setOpenEmail} />
      {loading ? (
        <RocketLoading />
      ) : (
        <>
          <h1>Entrar</h1>
          <div
            style={{
              height: '20px',
            }}
          />
          <h4>Coloque suas informações para entrar na gamefik</h4>
          <div
            style={{
              height: '40px',
            }}
          />
          <h3>Email</h3>
          <input
            type="text"
            onChange={changeUsernameHandler}
            value={username}
            name="username"
            placeholder="Coloque seu email"
            style={{
              padding: '16px',
              border: '2px solid #D0D5DD',
              borderRadius: '8px', // Optional: to match the rounded corners
              fontSize: '16px', // Optional: to match the font size
              width: '100%', // Optional: to make the input full width
              boxSizing: 'border-box', // Optional: to include padding and border in the element's total width and height
            }}
          />
          <div
            style={{
              height: '20px',
            }}
          />

          <h3>Senha</h3>
          <input
            name="password"
            type="password"
            value={password}
            onChange={changePasswordHandler}
            placeholder="Coloque sua senha"
            style={{
              padding: '16px',
              border: '2px solid #D0D5DD',
              borderRadius: '8px', // Optional: to match the rounded corners
              fontSize: '16px', // Optional: to match the font size
              width: '100%', // Optional: to make the input full width
              boxSizing: 'border-box', // Optional: to include padding and border in the element's total width and height
            }}
          />
          <div
            style={{
              height: '20px',
            }}
          />
          <Button
            type="submit"
            variant="contained"
            onClick={onSubmitHandler}
            style={{
              width: '100%',
              backgroundColor: '#53F1B6',
              height: '48px',
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            Fazer login
          </Button>
          <div
            style={{
              width: '100%',
              height: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setOpenEmail(true)}
              style={{
                fontWeight: 700,
                height: '48px',
              }}
            >
              Esqueceu sua senha?
            </Button>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Precisa de ajuda?
            <a href='https://intercom.help/gamefik/pt-BR'>
              <Button
                style={{
                  fontWeight: 700,
                  height: '48px',
                }}
              >
                Clique aqui
              </Button>
            </a>
          </div>

        </>
      )}
    </form>
  );
}

export default LoginBox;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Typography } from '@material-ui/core';
import Input from '../../atoms/input';
import AnimationFinishPost from '../animation-finish-post';
import Validator from '../../../services/validator';
import Client from '../../../services/client';

const client = new Client();

export default function ForgotPassword(props) {
  const [email, setEmail] = React.useState('');
  const [validation, setValidation] = React.useState(false);
  const [openFinish, setOpenFinish] = React.useState(false);
  const [success, setSuccess] = React.useState(true);
  const [messageText, setMessageText] = React.useState(null);

  const getErrorMessage = error => {
    const errorMap = {
      'User not found.':
        'Desculpe, mas não encontramos nenhum usuário cadastrado com esse e-mail.',
      'User not verified.':
        'Sua conta ainda não foi verificada, enviamos um novo convite para seu email.',
    };
    return (
      errorMap[error] ||
      'Desculpe, um erro ocorreu ao enviar o link par seu e-mail, por favor entre em contato com o suporte.'
    );
  };

  const sendEmail = async () => {
    try {
      await client.forgotPassword(email);
      props.setOpen(false);
      setMessageText('Enviamos um link de alteração de senha para seu e-mail!');
      setSuccess(true);
      setOpenFinish(true);
    } catch (error) {
      setMessageText(getErrorMessage(error.message));
      setOpenFinish(true);
      setSuccess(false);
    }
  };

  const closeDialog = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog scroll="paper" open={props.open}>
        <DialogTitle>
          <Typography align="center" variant="subtitle1" color="textSecondary">
            Digite o e-mail de sua conta para receber um link de alterção de
            senha
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} justify="center" alignItems="flex-end">
            <Grid item sm={12} xs={12}>
              <Input
                error={!validation && email !== ''}
                helperText={!validation && email !== '' && 'E-mail inválido'}
                value={email}
                onChange={async value => {
                  setValidation(await Validator.email(value));
                  setEmail(value.toLowerCase());
                }}
                label="E-mail"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant="contained">
            Cancelar
          </Button>
          <Button onClick={sendEmail} color="primary" variant="contained">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <AnimationFinishPost
        open={openFinish}
        closeAnimation={() => setOpenFinish(false)}
        success={success}
        messageText={messageText}
        textButton="ok"
      />
    </div>
  );
}

import TextField from '@material-ui/core/TextField';
import React from 'react';

function Input(props) {
  const [value, setValue] = React.useState('');
  const handleText = event => {
    setValue(event.target.value);
    props.onChange(event.target.value);
  };
  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <TextField
      required={props.required}
      fullWidth
      name={props.name}
      error={props.error}
      inputProps={props.inputProps}
      label={props.label}
      className={props.className}
      helperText={props.helperText}
      value={value}
      type={props.type}
      style={props.style}
      onChange={handleText}
      disabled={props.disabled || undefined}
      variant={props.variant}
      InputLabelProps={props.InputLabelProps}
    />
  );
}

export default Input;
